import { Component, Input } from '@angular/core';

export type BackgroundColor = 'default' | 'green'

@Component({
  selector: 'ax-component-library-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent {
  @Input() description = '';
  @Input() backgroundColor: BackgroundColor = 'default';
}

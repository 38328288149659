import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {
  AvatarComponent,
  ButtonComponent, CardModalOpenerComponent,
  CheckboxComponent,
  FilterComponent,
  IconComponent, InfoblockComponent,
  InputFieldComponent,
  LabelComponent,
  LogoComponent,
  ModalComponent,
  RadioGroupComponent,
  SelectComponent, TabContentDirective,
  TableFooterComponent,
  TableHeaderComponent, TabsComponent,
  ToggleSwitchComponent
} from '@axova-frontend-monorepo/axova-ui';
import { ContainerComponent } from './components/container/container.component';
import { ItemComponent } from './components/item/item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableDemoComponent } from './components/table-demo/table-demo.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TRANSLATE_FACTORY_MODULES, translateHttpModuleLoaderFactory } from '@axova-frontend-monorepo/axova-commons';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AxovaStateModule } from '@axova-frontend-monorepo/axova-state';

@NgModule({
  declarations: [
    AppComponent,
    ContainerComponent,
    ItemComponent,
    ItemComponent,
    TableDemoComponent
  ],
  imports: [
    BrowserModule,
    AxovaStateModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpModuleLoaderFactory,
        deps: [
          HttpClient,
          TRANSLATE_FACTORY_MODULES
        ]
      }
    }),
    LogoComponent,
    ButtonComponent,
    IconComponent,
    AvatarComponent,
    LabelComponent,
    CheckboxComponent,
    SelectComponent,
    InputFieldComponent,
    RadioGroupComponent,
    ToggleSwitchComponent,
    ModalComponent,
    TableFooterComponent,
    TableHeaderComponent,
    FilterComponent,
    TabContentDirective,
    CardModalOpenerComponent,
    TabsComponent,
    InfoblockComponent
  ],
  providers: [
    {
      provide: TRANSLATE_FACTORY_MODULES,
      useValue: [
        {
          moduleName: 'LIBRARY',
          baseTranslateUrl: './assets/i18n'
        }
      ]
    }
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    ContainerComponent,
    ItemComponent
  ]
})
export class AppModule {
}
